import mixins, { selectedModule } from '@/mixins'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'news',
  mixin: [mixins, selectedModule],
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null
    }
  },
  components: {
    CountryFlag
  },
  computed: {
    ...mapGetters({
      countries: 'setting/countries',
      newsList: 'news/newsList',
      loading: 'news/newsListLoading',
      lengthList: 'news/newsListLength',
      removeListItemLoading: 'news/removeListItemLoading',
      skip: 'news/skip',
      limit: 'news/limit',
      accessCountries: 'profile/accessCountries',
      filterSearchInput: 'news/filterSearchInput',
      filterSelectedCountries: 'news/filterSelectedCountries',
      filterSelectedStatus: 'news/filterSelectedStatus',
      setting: 'news/setting'
    }),
    headers () {
      const arr = [
        {
          text: 'Id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date created',
          value: 'createdAt'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
      return arr
    }
  },
  watch: {
    '$route' () {
      this.changeSkip(0)
      this.page = 1
      this.fetchList({ module: this.$route.name })
    }
  },
  created () {
    this.fetchList({ module: this.$route.name })
    if (!this.countries) {
      this.fetchCountries()
    }
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    this.setting || this.fetchSetting()
  },
  methods: {
    ...mapActions({
      fetchList: 'news/NEWS_FETCH_LIST',
      remove: 'news/NEWS_LIST_DELETE_ITEM',
      fetchCountries: 'setting/GET_COUNTRIES',
      fetchSetting: 'news/GET_SETTING'
    }),
    ...mapMutations({
      changeFilterSearchInput: 'news/NEWS_FILTER_CHANGE_SEARCH_INPUT',
      changeFilterCountry: 'news/NEWS_FILTER_CHANGE_COUNTRY',
      changeFilterType: 'news/NEWS_FILTER_CHANGE_TYPES',
      changeSkip: 'news/NEWS_LIST_CHANGE_SKIP'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList({ module: this.$route.name })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    removeListItem () {
      this.remove({
        ...this.removingItem,
        module: this.$route.name
      }).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeCountry (country) {
      this.changeFilterCountry(country || '')
      this.sendRequest()
    },
    changeInputSearch (val) {
      this.changeFilterSearchInput(val || '')
      this.sendRequest()
    },
    changeInputType (val) {
      this.changeFilterType(val || '')
      this.sendRequest()
    },
    sendRequest: debounce(function () {
      this.fetchList({ module: this.$route.name })
    }, 1000)
  }
}
